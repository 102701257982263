import ctl from "@netlify/classnames-template-literals";

export const sidebar = ctl(`
  hidden
  overflow-y-auto
  md:fixed
  md:flex
  md:h-full
  md:w-80
  md:shrink-0
  md:flex-col
  md:items-start
  md:border-r-2
  md:bg-white
  md:p-3
`);
